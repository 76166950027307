$(function(){	
	
	// //MDBootstrap problem. D'Oh!. Add active class to label of a text input
	// $('input[type=text].form-control,input[type=password].form-control').on('focus',function(){
		// $(this).parent().find('label').addClass('active');
	// })
	// //MDBootstrap problem. D'Oh!. Remove active class to label of a text input ONLY IF label is empty
	// $('input[type=text].form-control,input[type=password].form-control').on('blur',function(){
		// if($(this).val()=='') $(this).parent().find('label').removeClass('active');
	// })	
	
	//External links protection. Also ensure <a> elements have target=blank
	$('a.external').attr('target','_blank').attr('rel','noopener noreferrer').each(function(){
		if( ! $(this).hasClass('no-external-icon'))
			$(this).html($(this).html() + '<sup><i class="fa fa-external-link o30"></i></sup>');
	});

	
	//Add confirmation modal before accessing to a link
	$('a[data-confirm]').click(function( event ) {
		event.preventDefault();
		if(confirm($(this).data('confirm')))
			window.location.href = $(this).attr('href');		
	});
	
	
	$('.fixed-action-btn a.btn-floating[href]').on('click', function(){
		var href = $(this).attr('href');
		window.location.href = href;
	});

	
	// ACTIVATE TOOLTIPS
	$('[data-toggle="tooltip"]').tooltip();
	
	// FIXED FLOATING Button -  Follow HREF
	$('.fixed-action-btn li>a').on('click', function(){ window.location.href = $(this).attr('href'); });
	
	// //MANAGE .SELECTABLE table ROWS -----------------------------------------
	// $('tr.selectable td').not('.prevent-row-click').on('click', function(){
		
		// var checkbox = $(this).parents('tr').first().find('input[type=checkbox]'),
			// checkbox_el = checkbox.get(0),
			// checked = checkbox_el.checked;
		
		// checkbox_el.checked = ! checked;
		// checkbox.trigger('change');
		
		// update_selectable_checkbox();
		
	// });
	// $('tr.selectable input[type=checkbox]').on('change', function(){
		
		// if($(this).get(0).checked)
			// $(this).parents('tr').first().addClass('table-danger');
		// else
			// $(this).parents('tr').first().removeClass('table-danger');
		
	// });
	// update_selectable_checkbox('first-init'); //First init
	// // END: MANAGE selectable table rows --------------------------------
	
	$.ajaxSetup({
		headers: {
			'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
		}
	});
	
});

//==========================================================================
function sortupdate_ajax(event, ui)
{
	var url = ui.item.parents('form').first().attr('action');
	
	var data = ui.item.parents('form').first().serialize();
	
	$.post(url, data, function(x) { }, 'json');
}
